<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="index">
        <!-- 轮播 -->
        <swiper :options="swiperOption" ref="mySwiper" v-if="bannerList.length>0">
          <swiper-slide v-for="(item, index) in bannerList" :key="index">
            <img :src="oss + item" class="img_banner" alt="" />
          </swiper-slide>
        </swiper>
        <!-- 商品展示 -->
        <!-- <div class="fs32 tc title">商品展示</div> -->
        <ul class="flex-box flex-wrap exhibit_list m1440 pt30" id="conRef0">
          <li v-for="(item, index) in goodsList" :key="index" class="mb15" @click="jumpDetail(item.id)">
            <a href="javascript:;">
              <div class="img_box">
                <img :src="oss + item.thumbnail" class="img_goods" alt="" />
              </div>
              <div class="box">
                <div class="flex-box flex-between pb17">
                  <div class="red fs24"><span class="fs16">¥</span>{{ item.price_text?item.price_text:item.price}} <span class="col9 fs16">{{ item.unit?item.unit:'' }}</span></div>
                  <div class="fs16">成交量 {{ item.transactionNum ? item.transactionNum : 0 }}</div>
                </div>
                <div class="fs16 fwb pb15 single-line">{{ item.productName }}</div>
								<!-- add mao 240306-->
								<!-- <div class="fs14 single-line mb5">产品类型：{{ item.irecItemCode}}</div> -->
				<div class="fs14 single-line mb5">产品类型：{{ item.productTy}}</div>
				<div class="fs14 single-line mb5">产品小类：{{ item.productSub}}</div>
								<!-- add end -->
                <div class="fs14 single-line mb5">技术类型：{{ item.tecType }}</div>
                <div class="fs14 single-line mb5">年份：{{ item.year }}</div>
                <div class="fs14 single-line mb5">库存：{{ item.stocks }}</div>
                <div class="fs14 single-line">项目名称：{{ item.projectName ? item.projectName : '' }}</div>
              </div>
            </a>
          </li>
        </ul>
        <!-- 业务流程说明 -->
        <div class="bg_gray pt10" id="conRef1">
          <div class="fs32 tc title">业务流程说明</div>
          <div class="mt10 fs24 flex-box flex-center goods_process">
            <a href="javascript:;" @click="changePro(0)" :class="proIndex == 0 ? 'active' : ''">普通物品</a>
            <a href="javascript:;" @click="changePro(1)" :class="proIndex == 1 ? 'active' : ''">邀请码物品</a>
          </div>
          <div class="pb70">
            <img src="../assets/static/images/img_process1.png" class="img_process m1440" v-show="proIndex == 0"
              alt="" />
            <img src="../assets/static/images/img_process2.png" class="img_process m1440" v-show="proIndex == 1"
              alt="" />
          </div>
        </div>
       
        <!-- 常见问题 -->
        <div class="fs32 tc title" id="conRef2">常见问题</div>
        <ul class="m1440 qa_list">
          <li :class="quesIndex == index ? 'show' : ''" @click="changeQues(index)" v-for="(item, index) in question"
            :key="index" v-if="index < 3">
            <div class="ques flex-box flex-col-start">
              <div class="normal fs24">Q<span class="fs16">{{ index + 1 }}</span></div>
              <div class="fs18 col3 pl15 flex-grow-1">{{ item.question }}</div>
            </div>
            <div class="ans flex-box flex-col-start">
              <div class="normal fs24">答: </div>
              <!-- <div class="normal fs24">A<span class="fs16">{{ index + 1 }}</span></div> -->
              <div class="fs18 col3 pl15 flex-grow-1">{{ item.answer }}</div>
            </div>
          </li>
        </ul>
        <router-link to="/help" class="know_more">了解更多</router-link>

         <!-- 证书示例 -->
        <div class="fs32 tc title" id="conRef3">证书示例</div>
        <div v-for="(item, index) in certList" :key="index" v-if="index < 1">
          <img :src="oss + item" class="img_example m1440" alt="" />
        </div>
        <router-link to="/certificate_more" class="know_more">了解更多</router-link>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { get_chaos_hub } from "@/request/api";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      oss: '',
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      // 轮播图
      bannerList: [],
      certList: [],
      proIndex: 0, //流程下标
      question: [],//常见问题
      quesIndex: -1, //问题下标
      about_us: {},//关于我们
      milestones: {},
      // 显示分页
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      goodsList: [], //商品列表
    };
  },


  created() {
    let _that = this;
    _that.oss = _that.global.uploadOss
    // 获取首页数据
    _that.get_index();
    _that.get_banner();
    _that.get_question();
    _that.get_about();
  },
  methods: {
   
    // 业务流程切换
    changePro(index) {
      this.proIndex = index;
    },
    // 切换问题
    changeQues(index) {
      this.quesIndex = index;
    },

    //跳转详情
    jumpDetail(id) {
      this.$router.push("/goods_detail?id=" + id)
    },
    //获取关于我们
    get_about() {
      let s_data = {
        sortType: 'DESC',
        deviceType: '1',
      }
      this.$api.apiAboutUs(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root[0];
          if (data.milestones != '' && data.milestones != null) {
            data.milestones = JSON.parse(data.milestones)
            var milestones = data.milestones[0]
            this.milestones = milestones
          }
          this.about_us = data;
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
    //获取常见问题
    get_question() {
      let s_data = {
        sortType: 'DESC',
        deviceType: '1',
        limit: 10,
        type: '0'
      }
      this.$api.apiQuestion(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          this.question = data_res.root
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
    //获取首页数据
    get_banner() {
      this.$api.apiIndexBanner({ contentKeys: 'PC_BANNER,PC_CERTIFICATE'}).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root
          data.forEach(item => {
            if (item.ckName == 'PC_BANNER') {
              var img = item.pictureUrl.split(',')
              this.bannerList = img
            } else {
              var img = item.pictureUrl.split(',')
              this.certList = img
            }
          })
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },


    //获取首页数据
    get_index() {
      let that = this
      let s_data = {
        sortType: 'DESC',
        deviceType: '1',
        status: '1',
        forInvitation:'0' 
        // limit: 12
      }
      that.$api.apiGetIndex(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root
          if(data.length >0){
            data.forEach(function(item){
              item.price_text = that.global.addCommas(item.price)
            })
          }
          that.goodsList = data
        } else {
          that.$message.error(data_res.resMsg);
        }
      });
    },
  },
};
</script>
<style scoped>
.swiper-container {
  height: 40vh;
}

.img_banner {
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>